<template>
  <div class="user-feedback-container">
    <p class="section-title">Phản hồi từ khách hàng tại Dr.Minh</p>
    <div class="user-feedback-grid">
      <img
        @click="openFullPage('feedback-1')"
        class="user-feedback-item el-animation0-scale"
        src="../assets/feedback-1.png"
        loading="lazy"
      />
      <img
        @click="openFullPage('feedback-2')"
        class="user-feedback-item el-animation0-scale"
        src="../assets/feedback-2.png"
        loading="lazy"
      />
      <img
        @click="openFullPage('feedback-3')"
        class="user-feedback-item el-animation0-scale"
        src="../assets/feedback-3.png"
        loading="lazy"
      />
      <img
        @click="openFullPage('feedback-4')"
        class="user-feedback-item el-animation0-scale"
        src="../assets/feedback-4.png"
        loading="lazy"
      />
      <img
        @click="openFullPage('feedback-5')"
        class="user-feedback-item el-animation0-scale"
        src="../assets/feedback-5.png"
        loading="lazy"
      />
      <img
        @click="openFullPage('feedback-6')"
        class="user-feedback-item el-animation0-scale"
        src="../assets/feedback-6.png"
        loading="lazy"
      />
      <img
        @click="openFullPage('feedback-7')"
        class="user-feedback-item el-animation0-scale"
        src="../assets/feedback-7.png"
        loading="lazy"
      />
      <img
        @click="openFullPage('feedback-8')"
        class="user-feedback-item el-animation0-scale"
        src="../assets/feedback-8.png"
        loading="lazy"
      />
      <img
        @click="openFullPage('feedback-9')"
        class="user-feedback-item el-animation0-scale"
        src="../assets/feedback-9.png"
        loading="lazy"
      />
      <img
        @click="openFullPage('feedback-10')"
        class="user-feedback-item el-animation0-scale"
        src="../assets/feedback-10.png"
        loading="lazy"
      />
    </div>
    <p class="text-b32 hiding-temp">Tôi muốn được tư vấn 1:1</p>
    <div class="user-feedback-form hiding-temp">
      <FormAppointment btnText="Đặt lịch tư vấn 1:1"></FormAppointment>
    </div>
  </div>
  <div id="fullpage" onclick="this.style.display='none';"></div>
</template>

<script>
import FormAppointment from "./FormAppointment.vue";
export default {
  components: { FormAppointment },
  name: "UserFeedback",
  props: {
    msg: String,
  },
  methods: {
    openFullPage(imgName) {
      console.log(imgName);
      const fullPage = document.querySelector("#fullpage");
      var images = require.context("../assets/", false, /\.png$/);
      fullPage.style.backgroundImage =
        "url(" + images("./" + imgName + ".png") + ")";
      fullPage.style.display = "block";
    },
  },
};
</script>
<style scoped>
.user-feedback-container {
  width: 100vw;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;
}
.user-feedback-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 20px 80px 20px 80px;
}
.user-feedback-item {
  width: 15.3vw;
  height: 15.3vw;
  margin: 10px;
}
.user-feedback-form {
  width: 35%;
}
#fullpage {
  display: none;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-size: contain;
  background-repeat: no-repeat no-repeat;
  background-position: center center;
  background-color: black;
}
@media (max-width: 845px) {
  .user-feedback-grid {
    width: 100vw;
    flex-wrap: nowrap;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .user-feedback-grid::-webkit-scrollbar {
    display: none;
  }
  .user-feedback-item {
    width: 56.4vw;
    height: 56.4vw;
    display: inline-block;
  }
  .user-feedback-form {
    width: 100%;
  }
}
</style>
