<template>
  <div class="treatment-process-container">
    <p class="section-title">
      Tái phẫu thuật mí hỏng được thực hiện như thế nào?
    </p>
    <img
      v-observe-visibility="{
        callback: visibilityChanged,
        once: true,
      }"
      :class="[showAnimation ? 'el-animation-em' : '']"
      class="treatment-process-main-img"
      src="../assets/treatment-process.jpeg"
      loading="lazy"
    />
    <p class="text-r24-fixed no-margin text-center hiding-temp">
      Giảm ngay <br class="show-in-mobile" />
      <span class="text-b32 text-gold no-margin">10.000.000VNĐ </span>
      <br class="show-in-mobile" />đến hết 31/12/2023
    </p>
    <div class="treatment-process-form hiding-temp">
      <FormAppointment btnText="Đặt lịch tư vấn 1:1"></FormAppointment>
    </div>
  </div>
</template>

<script>
import FormAppointment from "./FormAppointment.vue";

export default {
  components: { FormAppointment },
  name: "TreatmentProcess",
  props: {
    msg: String,
  },
  data() {
    return {
      showAnimation: false,
    };
  },
  methods: {
    visibilityChanged(isVisible) {
      if (isVisible) {
        this.showAnimation = true;
      }
    },
  },
};
</script>
<style scoped>
.el-animation-em {
  animation: animation-show-em 1s ease-in-out;
}
@keyframes animation-show-em {
  0% {
    transform: scaleX(0.3) rotate(90deg);
  }
  100% {
    transform: scaleX(1) rotate(0deg);
  }
}
.treatment-process-container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px;
  padding-bottom: 50px;
  background-color: white;
}
.treatment-process-main-img {
  width: calc(100% - 100px);
  margin: 20px 0px;
}
.treatment-process-form {
  width: 30%;
}

@media (max-width: 845px) {
  .treatment-process-main-img {
    width: 96%;
    margin: 20px 2%;
  }
  .treatment-process-form {
    width: 96%;
    margin: 0px 2%;
  }
}
</style>
