<template>
  <div class="mobile-nav-container">
    <div class="mobile-nav-content">
      <div @click="toggleMenu" class="mobile-nav-item">
        <img src="../assets/f-nav-menu.svg" />
        <p>Menu</p>
      </div>
      <a href="tel:0889003636" class="mobile-nav-item">
        <img src="../assets/f-nav-phone.svg" />
        <p>Gọi điện</p>
      </a>
      <div @click="showAppointmentBox()" class="center-nav">
        <img src="../assets/f-nav-circle-center.png" />
        <p>Đặt lịch</p>
      </div>
      <a @click="showAppointmentBox(true)" class="mobile-nav-item">
        <img src="../assets/f-nav-gift.svg" />
        <p>Ưu đãi</p>
      </a>
      <a
        target="_blank"
        href="https://www.youtube.com/@drminhaesthetic6269/videos"
        class="mobile-nav-item"
      >
        <img src="../assets/f-nav-youtube.svg" />
        <p>Videos</p>
      </a>
    </div>
  </div>
  <div v-if="showMenu" @click="toggleMenu()" class="nav-menu-container">
    <div class="nav-menu-content">
      <p @click.stop="navTo('#seven-signs')" class="nav-menu-item">
        7 dấu hiệu mí hỏng
      </p>
      <p @click.stop="navTo('#treatment-way')" class="nav-menu-item">
        Hành trình chữa lành
      </p>
      <p @click.stop="navTo('#user-sharing')" class="nav-menu-item">
        Khách hàng trải lòng
      </p>
    </div>
  </div>
</template>

<script>
import { PageUtils } from "../PageUtils.ts";
export default {
  name: "MobileNav",
  props: {
    msg: String,
  },
  data() {
    return {
      showMenu: false,
    };
  },
  methods: {
    showAppointmentBox(showPromo = false) {
      console.log("showAppointmentBox");
      PageUtils.showAppointmentBox(showPromo);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    navTo(hashName) {
      this.showMenu = false;
      window.location = hashName;
    },
  },
};
</script>
<style scoped>
.mobile-nav-container {
  width: 100vw;
  min-height: 100px;
  background-image: url("../assets/f-nav-bg.png");
  background-size: 100vw 20.7vw;
  background-position: bottom;
  background-repeat: no-repeat;
  z-index: 999;
}
.mobile-nav-content {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.mobile-nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25vw;
  text-decoration: none;
}
.mobile-nav-item p {
  font-family: "Inter Regular";
  font-size: 12px;
  text-align: center;
  color: #777;
  margin: 10px 0px 10px 0px;
}
.center-nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25vw;
  text-decoration: none;
  margin-top: -20px;
}
.center-nav img {
  margin-bottom: -5px;
}
.center-nav p {
  font-family: "Inter Regular";
  font-size: 12px;
  text-align: center;
  color: #777;
  margin: 10px 0px 10px 0px;
}
.nav-menu-container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  bottom: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: rgba(255, 255, 255, 0.7);
}
.nav-menu-content {
  /* background-color: white; */
  background-image: linear-gradient(
    90deg,
    #0c4e5a 0%,
    #0c4c58 15.19%,
    #093745 42.89%,
    #06232b 76.17%
  );
  display: flex;
  flex-direction: column;
  border-top: solid 1px var(--color-primary);
  z-index: 900;
  padding-bottom: 30vw;
}
.nav-menu-item {
  margin: 12px 8px 12px 12px;
  font-family: "Inter Bold";
  font-size: 20px;
  text-align: center;
  color: white;
  text-decoration: none;
}
</style>
