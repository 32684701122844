<template>
  <div class="connect-option-container">
    <div class="option-container-top show-in-desktop">
      <!-- <div class="option-short">
        <img src="../assets/connect-chat-pri.svg" />
        <div class="option-short-hover">
          <p>Chat ngay</p>
          <img src="../assets/connect-chat-white.svg" />
        </div>
      </div> -->
      <div class="option-short">
        <img src="../assets/connect-calendar-pri.svg" />
        <div
          @click="showAppointmentBox()"
          class="option-short-hover el-animation-scale-x"
        >
          <p>Đặt lịch tư vấn 1:1</p>
          <img src="../assets/connect-calendar-white.svg" />
        </div>
      </div>
      <div class="option-short">
        <img src="../assets/connect-phone-pri.svg" />
        <a
          href="tel:0889003636"
          class="option-short-hover el-animation-scale-x"
        >
          <p>088 900 36 36</p>
          <img src="../assets/connect-phone-white.svg" />
        </a>
      </div>
    </div>
    <a href="https://zalo.me/0889003636" class="option-item-outside">
      <img src="../assets/connect-zalo.png" />
    </a>
    <a
      target="_blank"
      href="https://www.messenger.com/t/2477482918948190"
      class="option-item-outside"
    >
      <img src="../assets/connect-messenger.png" />
    </a>
  </div>
</template>

<script>
import { PageUtils } from "../PageUtils.ts";
export default {
  name: "ConnectOption",
  props: {
    msg: String,
  },
  methods: {
    showAppointmentBox: function () {
      PageUtils.showAppointmentBox();
    },
  },
};
</script>
<style scoped>
.connect-option-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: fixed;
  right: 12px;
  bottom: 90px;
}
.option-container-top {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 8px;
  padding: 0px 0px 8px 0px;
  margin-right: 0px;
}

.option-short {
  width: 40px;
  height: 40px;
  padding: 8px 16px;
  display: flex;
  flex-direction: row;
}
.option-short img {
  width: 40px;
  height: 40px;
}
.option-short-hover {
  width: 180px;
  position: absolute;
  right: 8px;
  display: none;
  flex-direction: row;
  align-items: center;
  background-color: var(--color-primary);
  border-radius: 8px;
  padding: 0px 8px;
  cursor: pointer;
}
.option-short-hover a {
  text-decoration: none;
}
.option-short-hover a:visited {
  color: white;
  text-decoration: none;
}
.option-short-hover p {
  font-family: "Inter Bold";
  font-size: 16px;
  color: white;
  flex: 1;
  margin-right: 10px;
}
.option-short-hover img {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}
.option-short:hover .option-short-hover {
  display: flex;
}
.option-item-outside {
  width: 40px;
  height: 40px;
  padding: 0px 16px;
  margin-top: 10px;
}
.option-item-outside img {
  width: 40px;
  height: 40px;
}
@media (max-width: 845px) {
  .option-item-outside {
    width: 50px;
    height: 50px;
    margin-right: 5px;
  }
  .option-item-outside img {
    width: 50px;
    height: 50px;
  }
  .connect-option-container {
    right: 0px;
    bottom: 80px;
  }
}
</style>
