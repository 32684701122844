<template>
  <GHeader></GHeader>
  <MainContent></MainContent>
  <GFooter></GFooter>
  <ConnectOption></ConnectOption>
  <div class="mobile-nav show-in-mobile">
    <MobileNav></MobileNav>
  </div>
  <BoxPromotion
    @closePromotion="closeBoxPromotion()"
    @preventParentClick="ppc()"
    v-if="showBox"
  ></BoxPromotion>
  <div>
    <BoxAppointment></BoxAppointment>
  </div>
</template>
<script>
import GHeader from "./components/GHeader.vue";
import MainContent from "./components/MainContent.vue";
import GFooter from "./components/GFooter.vue";
import MobileNav from "./components/MobileNav.vue";
import ConnectOption from "./components/ConnectOption.vue";
import BoxPromotion from "./components/BoxPromotion.vue";
import BoxAppointment from "./components/BoxAppointment.vue";
export default {
  name: "App",
  components: {
    GHeader,
    MainContent,
    GFooter,
    MobileNav,
    ConnectOption,
    BoxPromotion,
    BoxAppointment,
  },
  data() {
    return {
      showBox: true,
    };
  },
  mounted() {
    console.log("box mounted");
    // setTimeout(() => {
    //   this.closeBoxPromotion();
    // }, 5000);
  },
  methods: {
    closeBoxPromotion() {
      this.showBox = false;
    },
    ppc() {
      console.log("ppc");
    },
  },
};
</script>

<style>
html {
  scroll-behavior: smooth;
}
#app {
  font-family: "Inter Regular", sans-serif;
  color: var(--color-primary);
  background-color: var(--color-primary);
}
.mobile-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  widows: 100%;
}
</style>
