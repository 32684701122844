<template>
  <div class="user-story-container">
    <p class="section-title">Câu chuyện khách hàng</p>
    <p class="user-story-intro">
      Hành trình tái phẫu thuật mí hỏng không chỉ dừng lại việc chữa lành các
      tổn thương bên ngoài. Chúng tôi còn giúp khách hàng chữa lành vết thương
      tâm lý của họ giúp họ tự tin hơn & xinh đẹp hơn. Đây là sứ mệnh mà chúng
      tôi tự hào thực hiện, mang lại sự hài lòng và hạnh phúc cho mỗi khách hàng
      tìm đến Dr.Minh
    </p>
    <div class="f-row mt-20 align-center">
      <img
        @click="goPrev"
        class="ic-arrow show-in-desktop"
        src="../assets/ic-arrow-left.svg"
      />
      <div class="user-storuy-slide-container">
        <carousel
          ref="userStorySlide"
          :autoplay="5000"
          :wrap-around="true"
          :items-to-show="1"
        >
          <slide v-for="story in storyList" :key="story.id">
            <div class="user-story-item mr-10">
              <div class="f-col mr-20">
                <p class="text-b24 text-justify no-margin">{{ story.title }}</p>
                <p class="text-r16">{{ story.status }}</p>
                <p class="text-r16">{{ story.content }}</p>
              </div>
              <img
                class="user-story-img"
                :src="getImgUrl(story.imageName)"
                loading="lazy"
              />
            </div>
          </slide>
          <template #addons>
            <Pagination />
          </template>
        </carousel>
      </div>
      <img
        @click="goNext"
        class="ic-arrow show-in-desktop"
        src="../assets/ic-arrow-right.svg"
      />
    </div>
  </div>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination } from "vue3-carousel";
export default {
  name: "UserStory",
  props: {
    msg: String,
  },
  data() {
    return {
      storyList: [
        {
          id: 1,
          title: "CHỊ LÝ KIM NGÂN - 27 tuần TP.HCM",
          status:
            "Tình trạng: đã phẫu thuật 4 lần, sẹo chằng chịt, nhắm mắt không kín",
          content:
            "“Lúc trước mình làm phẫu thuật ở một spa cắt mắt, do tay nghề kém nên khiến mắt mình bị trợn, sẹo, mắt nhắm không kín. Sau một thời gian mình được bạn bè giới thiệu bác sĩ Minh là sửa chữa những ca mí hỏng rất là thành công cho nên mình muốn qua là nhờ bác kiểm tra mí hỏng của mình. Bây giờ thì mắt mình đã khép kín lại rồi, phần sẹo sắp tới cũng sẽ được bác Minh xử lý thẩm mỹ, giúp mình lấy lại được vẻ tự tin. Mình thật sự rất hạnh phúc”",
          imageUrl: "../assets/user-story-1.png",
          imageName: "user-story-1",
        },
        {
          id: 2,
          title: "Huỳnh Thị Kim Tuyến - 33 tuổi - Malaysia",
          status:
            "Tình trạng: Mí to không vào nếp, mắt mở bị vướng, sẹo góc mắt trong, hốc mắt sâu",
          content:
            '"Trước khi đến với bác sĩ Minh, mắt của tôi bị hốc mắt sâu, 1 bên tuột nếp mí, mắt nhìn thảm hại lắm, già mà không có sức sống. Khi đến phẫu thuật tội nghĩ chỉ được 80% là mừng lắm rồi, ai ngờ giờ tôi hài lòng 100%. Bác sĩ làm rất có tâm, rất kỹ, có bạn bè gì tôi cũng sẽ giới thiệu, không những mắt nữ mà mắt nam bác cũng làm rất có tâm. Một lần nữa tôi muốn gửi lời cảm ơn bác sĩ đã cứu lấy cặp mắt của tôi."',
          imageUrl: "../assets/user-story-2.png",
          imageName: "user-story-2",
        },
        {
          id: 3,
          title: "Kara Nguyễn - 54 tuổi - Úc",
          status: "Tình trạng: Mí không đều, mắt nhỏ và hốc mắt sâu, mô sẹo",
          content:
            '"Trước đây hồi còn trẻ tôi có nhấn mí sơ qua thôi, nhưng lâu rồi mí bị sụp, không còn tự tin nữa. Tôi cũng có tìm hiểu bác sĩ thẩm mỹ ở Úc, Thái Lan và Hàn rồi nhưng vẫn không đủ tin tưởng. Đến khi nghe 2 người bạn giới thiệu bác sĩ uy tín tại Việt Nam mà họ đã từng làm rồi, tôi mới an tâm bay về nước để đăng ký. Lúc đầu, tôi chỉ tính cắt mỡ thôi nhưng dịch vụ tốt quá nên tôi làm toàn diện luôn để có đôi mắt trẻ trung. Đúng là kết quả ngoài mong đợi, tôi được bác sĩ Minh thăm khám tận tình và đội ngũ nhân viên chăm sóc kỹ lưỡng. Mình vô cùng hài lòng về đôi mắt của mình hiện tại."',
          imageUrl: "../assets/user-story-3.png",
          imageName: "user-story-3",
        },
        {
          id: 4,
          title: "Hồ Bích Thuỳ - 27 tuổi - TP.HCM",
          status:
            "Tình trạng: bị lật mi, chảy nước mắt, mí trên lờ đờ, nhìn dữ, sâu, trợn, vểnh mi, mí to",
          content:
            '"Lúc trước là mắt tôi ai cũng khen đẹp nhưng mà do là tôi lướt Facebook mà thấy cái spa đăng tuyển mẫu phẫu thuật theo trend hạ giọt lệ trung hoa giá rẻ nên là tôi theo làm. Ai ngờ, ở đó không phải là bác sĩ mà thực tập viên thực hiện, làm xong mắt nhìn rất ghê, mất thẩm mỹ, còn bị chảy nước mắt, mí trên lờ đờ, ai cũng cười chê. Cũng may tôi đọc thông tin thấy bác sĩ Minh là chuyên gia sửa mí hỏng, thấy đúng với cái mà mình đang bị nên tìm đến phòng khám, được bác Minh tư vấn rất kỹ càng. Sau khi tái phẫu thuật mí hỏng, các bạn nhân viên ở Dr.Minh cũng dặn dò chăm sóc kỹ lưỡng nên mắt tôi giờ gần như trở lại bình thường, một bài học nhớ đời cho tôi."',
          imageUrl: "../assets/user-story-4.png",
          imageName: "user-story-4",
        },
        {
          id: 5,
          title: "Võ Thị Mộng Thuý - 48 tuổi - Nha Trang",
          status:
            "Tình trạng: Mắt mở giới hạn, căng góc mắt trong, đa nếp mí, sẹo góc mắt, hốc mắt sâu",
          content:
            '"Trước khi đến với bác Minh, mắt của tôi thực sự rất xấu, rất kinh, giờ còn không dám nghĩ tới, thậm chí có lúc tôi đã muốn tự tử khi nhìn thấy nó. May mắn là tôi đã gặp được bác Minh, một bác sĩ rất có tâm, rất kỹ, tôi chưa từng thấy ai kỹ như bác Minh. Sự tận tuỵ của bác khiến tôi thấy không đau xíu nào. Mắt tôi sau khi được bác Minh sửa mí đã cải thiện rất nhiều, ai cũng khen khiến tôi vui lắm. Với kinh nghiệm của mình, tôi khuyên mọi người nếu muốn phẫu thuật mí, nhất là tái phẫu thuật thì hãy tới với bác Minh vì chắc chắn bạn sẽ hài lòng"',
          imageUrl: "../assets/user-story-5.png",
          imageName: "user-story-5",
        },
        {
          id: 6,
          title: "Lê Thị Thanh Tuyền - 48 tuổi - TPHCM",
          status: "Tình trạng: Mắt trợn, mí to, nặng mí",
          content:
            '"Cách đây 2 năm, khi đi tới spa làm đẹp, tôi nghe người ta khuyên làm mí Ấn Độ đẹp lắm, thế là làm theo, ai ngờ, làm ra mắt to nhìn rất xấu, ngước lên mắt rất nặng, ai cũng chê, làm tôi nghĩ không lẽ mình bị vậy hoài. Tình cờ tôi nghe được cuộc phỏng vấn của bác Minh về việc sửa mí hỏng, thế là tôi quyết định tới bác Minh để tư vấn. Bác rất tận tâm khiến tôi đặt niềm tin để bác sửa mí cho. Sửa xong mắt tôi không còn trợn, to mà trở về lúc ban đầu, đi đâu ai cũng khen. Tôi thấy như mình được hồi sinh trở lại vậy"',
          imageUrl: "../assets/user-story-6.png",
          imageName: "user-story-6",
        },
        {
          id: 7,
          title: "Muller Sver - 53 tuổi - Đức",
          status:
            "Tình trạng: Lật mí dưới, mô sẹo dài tới gò má 2 bên, bờ sụn mi bên trái yếu từ trong ra ngoài không còn độ bám, gò má sâu",
          content:
            '"Trước khi gặp Bác sĩ Minh, mắt tôi bị khô, đỏ, viêm kết mạc do bị lật mí nhiều quá nên gió vào khiến tôi rất khó chịu. Tôi stress khi nhìn khuôn mặt biến dạng, tôi rất buồn bực và khó chịu. Vợ tôi khuyên tôi tới bác Minh vì nghe tiếng đã lâu. Sau khi tới bác Minh, phẫu thuật xong, ra ngoài gió tôi không còn thấy khó chịu, mắt không đau như trước, bác sĩ phẫu thuật rất tốt, rất thoải mái, không hề đau.Tôi rất biết ơn bác sĩ."',
          imageUrl: "../assets/user-story-7.png",
          imageName: "user-story-7",
        },
      ],
    };
  },
  methods: {
    getImgUrl(img) {
      var images = require.context("../assets/", false, /\.png$/);
      return images("./" + img + ".png");
    },
    goNext() {
      this.$refs.userStorySlide.next();
    },
    goPrev() {
      this.$refs.userStorySlide.prev();
    },
  },
  components: {
    Carousel,
    Slide,
    Pagination,
  },
};
</script>
<style scoped>
.user-story-container {
  width: 100vw;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;
}
.user-story-intro {
  width: 73.3vw;
  text-align: center;
  margin: auto;
  margin-top: 10px;
  font-family: "Inter Regular";
  font-size: 24px;
}
.user-storuy-slide-container {
  width: 70vw;
}
.user-story-item {
  width: 70vw;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.user-story-img {
  width: 100%;
}
.ic-arrow {
  margin: 0px 20px 0px 20px;
  cursor: pointer;
}
@media (max-width: 845px) {
  .user-story-intro {
    width: 95.9vw;
    font-size: 14px;
  }
  .user-storuy-slide-container {
    width: 98vw;
    margin-left: 3vw;
  }
  .user-story-item {
    width: 95.9vw;
    flex-direction: column;
  }
  .user-story-img {
    width: 75%;
  }
}
</style>
