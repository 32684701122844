<template>
  <div class="why-us-container">
    <p class="section-title">
      Vì sao khách hàng <br class="show-in-mobile" />
      lựa chọn Dr.Minh?
    </p>
    <div
      :class="[
        showItem1 ? 'el-animation-bounce-in' : 'el-animation-before-start',
      ]"
      class="why-us-row show-in-desktop"
    >
      <img class="why-us-item" src="../assets/why-us-1.png" loading="lazy" />
      <img class="why-us-item" src="../assets/why-us-2.png" loading="lazy" />
      <img class="why-us-item" src="../assets/why-us-3.png" loading="lazy" />
      <img class="why-us-item" src="../assets/why-us-4.png" loading="lazy" />
    </div>
    <div
      v-observe-visibility="{
        callback: visibilityChanged,
        once: false,
      }"
    ></div>
    <img
      class="why-us-item show-in-mobile mt-20"
      src="../assets/why-us-full.jpeg"
      loading="lazy"
    />
    <p class="section-title">
      Công nghệ & <br class="show-in-mobile" />
      Trang thiết bị hiện đại
    </p>
    <p class="why-us-tech-content">
      Qua đó Dr.Minh mong muốn bạn được trải nghiệm an toàn - thoải mái - hạnh
      phúc
    </p>
    <div
      :class="[showItemTech ? 'el-animation-move-vertical' : '']"
      class="why-us-row"
    >
      <img class="why-us-tech-item" src="../assets/tech-1.png" loading="lazy" />
      <img class="why-us-tech-item" src="../assets/tech-2.png" loading="lazy" />
      <img class="why-us-tech-item" src="../assets/tech-3.png" loading="lazy" />
    </div>
    <div
      v-observe-visibility="{
        callback: visibilityTechChanged,
        once: true,
      }"
    ></div>
  </div>
</template>

<script>
export default {
  name: "WhyUs",
  props: {
    msg: String,
  },
  data() {
    return {
      showItem1: false,
      showItemTech: false,
    };
  },
  methods: {
    visibilityChanged(isVisible) {
      console.log("why us: " + isVisible);
      if (isVisible) {
        this.showItem1 = true;
      }
    },
    visibilityTechChanged(isVisible) {
      console.log("why us tech: " + isVisible);
      if (isVisible) {
        this.showItemTech = true;
      }
    },
  },
};
</script>
<style scoped>
.el-animation-before-start {
  opacity: 0;
}
.el-animation-bounce-in {
  opacity: 1;
  animation: ani-bounce-in 3s ease-in-out;
}
@keyframes ani-bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}

.el-animation-move-vertical {
  animation: move-vertical 1s ease-in-out;
}
@keyframes move-vertical {
  0% {
    transform: translateY(-60px);
  }
  50% {
    transform: translateY(30px);
  }
  75% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}
.why-us-container {
  width: 100vw;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
}
.why-us-row {
  width: 88.8vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 80px 80px;
}
.why-us-item {
  width: 21vw;
  height: 30.5vw;
}
.why-us-tech-content {
  font-family: "Inter Regular";
  font-size: 24px;
  margin: 0px;
  text-align: center;
}
.why-us-tech-item {
  width: 22.7vw;
  height: 24.1vw;
}
@media (max-width: 845px) {
  .why-us-item {
    width: 95.8vw;
    height: auto;
    margin-bottom: 20px;
  }
  .why-us-tech-content {
    font-size: 14px;
    margin: 10px 20px;
  }
  .why-us-row {
    width: 100vw;
    overflow-x: auto;
    margin: 20px;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .why-us-row::-webkit-scrollbar {
    display: none;
  }
  .why-us-tech-item {
    width: 95vw;
    height: auto;
  }
}
</style>
