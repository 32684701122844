<template>
  <div
    v-observe-visibility="{
      callback: visibilityChanged,
      once: true,
    }"
    class="the-number-container"
  >
    <img class="curve show-in-desktop" src="../assets/curve-numbers-t.png" />
    <div class="flex-1 f-col w-100 justify-center">
      <p class="text-title-l1">Chúng tôi biết ơn & tự hào</p>
      <p class="text-title-l2 no-margin">
        QUÝ KHÁCH HÀNG TRONG VÀ NGOÀI NƯỚC <br class="show-in-desktop" />
        ĐÃ TIN TƯỞNG VÀ LỰA CHỌN
      </p>
      <p class="text-title-l3">
        (Việt Nam, Mỹ, Úc, Đài Loan, Nhật, Đức, Singapore.....)
      </p>
      <div class="stat-container">
        <div class="stat-card">
          <div class="stat-cont">
            <p class="stat-num">{{ numberCol1 }}+</p>
            <p class="stat-unit">Khách hàng</p>
          </div>
          <p class="stat-label">Tư vấn trực tiếp 1:1<br />với chuyên gia</p>
        </div>
        <div class="stat-card-reverse">
          <div class="stat-cont">
            <p class="stat-num">{{ numberCol2 }}+</p>
            <p class="stat-unit">Khách hàng</p>
          </div>
          <p class="stat-label">Được Dr. Minh giải cứu<br />thành công</p>
        </div>
        <div class="stat-card">
          <div class="stat-cont">
            <p class="stat-num">{{ numberCol3 }}+</p>
            <p class="stat-unit">Khách hàng</p>
          </div>
          <p class="stat-label">Tự tin & không sợ tiếp<br />xúc người lạ</p>
        </div>
        <div class="stat-card-reverse">
          <div class="stat-cont">
            <p class="stat-num">{{ numberCol4 }}+</p>
            <p class="stat-unit">Khách hàng</p>
          </div>
          <p class="stat-label">Chia sẻ & giới thiệu<br />bạn bè, người thân</p>
        </div>
      </div>
    </div>
    <img class="curve" src="../assets/curve-numbers-b.png" />
  </div>
</template>

<script>
export default {
  name: "TheNumbers",
  props: {
    msg: String,
  },
  data() {
    return {
      numberCol1: 0,
      numberCol2: 0,
      numberCol3: 0,
      numberCol4: 0,
      numberCol1Max: 14000,
      numberCol2Max: 12000,
      numberCol3Max: 12000,
      numberCol4Max: 10000,
    };
  },
  methods: {
    visibilityChanged(isVisible) {
      if (isVisible) {
        console.log("start counting");
        this.countUp();
      }
    },
    countUp() {
      if (this.numberCol1 < this.numberCol1Max) {
        this.numberCol1 = this.numberCol1 + 50;
      }
      if (this.numberCol2 < this.numberCol2Max) {
        this.numberCol2 = this.numberCol2 + 50;
      }
      if (this.numberCol3 < this.numberCol3Max) {
        this.numberCol3 = this.numberCol3 + 50;
      }
      if (this.numberCol4 < this.numberCol4Max) {
        this.numberCol4 = this.numberCol4 + 50;
      }
      setTimeout(() => {
        if (
          this.numberCol1 < this.numberCol1Max ||
          this.numberCol2 < this.numberCol2Max ||
          this.numberCol3 < this.numberCol3Max ||
          this.numberCol4 < this.numberCol4Max
        ) {
          setTimeout(() => {
            this.countUp();
          }, 20);
        }
      }, 10);
    },
  },
};
</script>
<style scoped>
.the-number-container {
  width: 100vw;
  height: 62.5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("../assets/bg-the-numbers.png");
  background-position: center;
}
.text-title-l1 {
  font-family: "Inter Regular";
  font-size: 32px;
  color: #2db4d7;
  text-align: center;
}
.text-title-l2 {
  font-family: "Inter Bold";
  font-size: 40px;
  color: white;
  text-align: center;
}
.text-title-l3 {
  font-family: "Inter Regular";
  font-size: 24px;
  color: #2db4d7;
  text-align: center;
}
.stat-card,
.stat-card-reverse {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.stat-container {
  display: flex;
  flex-direction: row;
  width: calc(100% - 160px);
  margin: 0px 80px;
  justify-content: space-between;
}
.stat-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 14.7vw;
  height: 14.7vw;
  background-image: url("../assets/circle-number.svg");
  background-repeat: no-repeat;
  background-size: 14.7vw 14.7vw;
}

.stat-num {
  font-family: "Inter Bold";
  font-size: 32px;
  color: white;
  text-align: center;
  margin: 0px;
  padding: 0px;
}

.stat-unit {
  font-family: "Inter Regular";
  font-size: 16px;
  color: white;
  text-align: center;
  margin: 0px;
  padding: 0px;
}

.stat-label {
  flex: none;
  font-family: "Inter Bold";
  font-size: 24px;
  color: white;
  text-align: center;
  margin: 0px;
  padding: 0px;
}
@media (max-width: 845px) {
  .the-number-container {
    height: auto;
  }
  .text-title-l1,
  .text-title-l3 {
    font-size: 20px;
    padding: 0px 20px;
  }
  .text-title-l2 {
    font-size: 24px;
    padding: 0px 20px;
  }
  .stat-container {
    flex-direction: column;
    width: 96%;
    margin: 0px 2%;
  }
  .stat-card {
    flex-direction: row;
  }
  .stat-card-reverse {
    flex-direction: row-reverse;
  }
  .stat-cont {
    width: 140px;
    height: 140px;
    background-size: 140px 140px;
  }
  .stat-num {
    font-size: 20px;
  }

  .stat-unit {
    font-size: 14px;
  }

  .stat-label {
    flex: 1;
    font-size: 20px;
    text-align: center;
  }
}
</style>
