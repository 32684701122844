export const PageUtils = {
  showAppointmentBox: (showPromotion = false) => {
    const appointmentBox = document.getElementById("box-appointment");
    if (showPromotion) {
      document.getElementById("box-appointment-promotion").style.display =
        "flex";
      document.getElementById("box-appointment-promotion-short").style.display =
        "none";
    } else {
      document.getElementById("box-appointment-promotion").style.display =
        "none";
      document.getElementById("box-appointment-promotion-short").style.display =
        "flex";
    }
    if (appointmentBox) {
      appointmentBox.style.display = "flex";
    }
  },
  hideAppointmentBox: () => {
    const appointmentBox = document.getElementById("box-appointment");
    if (appointmentBox) {
      appointmentBox.style.display = "none";
    } else {
      console.log("appointmentBox is null");
    }
  },
  isShouldNewsLogoScroll: () => {
    const elHidden = document.getElementById("logo-should-scroll");
    console.log("elHidden.value: " + elHidden.value);
    return elHidden.value == 1;
  },
  setShouldNewsLogoScroll: (shouldScroll) => {
    const elHidden = document.getElementById("logo-should-scroll");
    elHidden.value = shouldScroll ? 1 : 0;
  },
};
