<template>
  <div id="promotion" class="sale-form-container f-row">
    <div class="sale-col w-55 align-center">
      <p class="text-b40">Giảm ngay</p>
      <p class="promotion-value text-b60">10.000.000VNĐ</p>
      <p class="text-r24">khi sử dụng dịch vụ</p>
      <p class="text-b32 text-color-gradient-1">Tái phẫu thuật mí hỏng</p>
      <!-- <p class="text-b40 text-gold-gradient">EYELID REVISION</p> -->
      <img class="mt-20 mb-20" src="../assets/text-gold-eyelid.png" />
      <p class="text-b24">đến hết 31/12/2023<br />nhanh tay đăng ký</p>
    </div>
    <div class="sale-col flex-2">
      <p class="text-b24 w-75 text-center">Nhận ưu đãi & Đặt lịch chuyên gia</p>
      <div class="w-75 mt-20">
        <FormAppointment btnText="Đặt lịch tư vấn 1:1"></FormAppointment>
      </div>
    </div>
  </div>
</template>

<script>
import FormAppointment from "./FormAppointment.vue";
export default {
  name: "SectionPromotion",
  props: {
    msg: String,
  },
  components: {
    FormAppointment,
  },
};
</script>
<style scoped>
.sale-form-container {
  width: 100vw;
  height: 46.25vw;
  background-image: url("../assets/bg-section-promotion.jpeg");
  background-size: contain;
}
.sale-col {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
}
.sale-col p {
  margin: 10px 0px;
  text-align: center;
}
.promotion-value {
  width: 38vw;
  height: 6.2vw;
  color: white;
  padding: 10px;
  background-image: url("../assets/bg-promotion-value.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.text-color-gradient-1 {
  background: linear-gradient(
    90deg,
    #0c4e5a 0%,
    #0c4c58 15.19%,
    #093745 42.89%,
    #06232b 76.17%
  );
  background-clip: text;
  color: transparent !important;
  -webkit-background-clip: text;
}
</style>
