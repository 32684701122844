<template>
  <div class="news-sharing-container">
    <p class="section-title">Báo chí nói về ThS. BS Nguyễn Anh Minh</p>
    <div class="news-sharing-content">
      <div class="news-sharing-video">
        <iframe
          width="auto"
          height="auto"
          class="flex-1"
          src="https://www.youtube.com/embed/HfIGoEeEm50?si=CbzWShgzm8veVIqX"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
        <a
          target="_blank"
          href="https://www.youtube.com/watch?v=HfIGoEeEm50"
          class="text-video-title-fixed"
        >
          HTV - CẮT MÍ MẮT ĐƠN GIẢN LẮM - CƠ SỞ NÀO LÀM CŨNG ĐƯỢC!?
        </a>
      </div>
      <div
        v-observe-visibility="{
          callback: visibilityChanged,
          once: false,
        }"
        id="logo-scrolling"
        class="news-logo-scroll show-in-desktop"
      >
        <div class="news-logo-container">
          <div
            class="news-sharing-item"
            v-for="newsItem in newsList"
            :key="newsItem.id"
          >
            <a :href="newsItem.url">
              <img
                class="news-logo"
                :src="getImgUrl(newsItem.imageName, newsItem.imageExtension)"
              />
            </a>
          </div>
        </div>
      </div>
      <div class="news-logo-container show-in-mobile">
        <carousel
          style="width: 100%"
          :autoplay="3000"
          :wrap-around="true"
          :items-to-show="3"
        >
          <slide v-for="newsItem in newsList" :key="newsItem.id">
            <a target="_blank" :href="newsItem.url">
              <img
                class="news-logo"
                :src="getImgUrl(newsItem.imageName, newsItem.imageExtension)"
              />
            </a>
          </slide>
          <template #addons>
            <Pagination />
          </template>
        </carousel>
      </div>
    </div>
    <input id="logo-should-scroll" type="hidden" value="0" />
  </div>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination } from "vue3-carousel";
import { PageUtils } from "../PageUtils.ts";
export default {
  name: "NewsSharing",
  props: {
    msg: String,
  },
  data() {
    return {
      shouldScroll: false,
      newsList: [
        {
          id: 1,
          url: "https://www.htv.com.vn/phuong-phap-moi-chinh-sua-mi-mat-hong?fbclid=IwAR1ArvIwC3NgOob0Xs-_qoL5ufkWG02iHZ0-2QBYB57-4sp3m-3c69Aev6Y&fbclid=IwAR1ArvIwC3NgOob0Xs-_qoL5ufkWG02iHZ0-2QBYB57-4sp3m-3c69Aev6Y",
          imageName: "news-logo-htv",
          imageExtension: "png",
        },
        {
          id: 2,
          url: "https://nguoinoitieng.net/lam-dep/dr-minh-noi-khong-voi-nhung-vi-pham-trong-tham-my.html",
          imageName: "news-logo-nnt",
          imageExtension: "png",
        },
        {
          id: 3,
          url: "https://phongcachdoisong.vn/bac-si-minh-doc-quyen-cong-nghe-lam-dep-cho-doi-mat/?fbclid=IwAR0X0Q5FoTj4-A04YlUi8enIH9Py_a8IcdZG2qFXdHEEhAW1Bjz9AHLFH3Q",
          imageName: "news-logo-pcds",
          imageExtension: "png",
        },
        {
          id: 4,
          url: "https://phunuhiendai.vn/tin-chieu-tro-quang-cao-nhieu-khach-hang-khong-khoc/",
          imageName: "news-logo-pnhd",
          imageExtension: "png",
        },
        {
          id: 5,
          url: "https://eva.vn/",
          imageName: "news-logo-eva",
          imageExtension: "svg",
        },
        {
          id: 6,
          url: "https://afamily.vn/",
          imageName: "news-logo-afamily",
          imageExtension: "svg",
        },
        {
          id: 7,
          url: "https://www.phunuonline.com.vn/",
          imageName: "news-logo-pno",
          imageExtension: "png",
        },
        {
          id: 8,
          url: "https://phunutoday.vn/",
          imageName: "news-logo-pntd",
          imageExtension: "png",
        },
      ],
    };
  },
  components: {
    Carousel,
    Slide,
    Pagination,
  },
  mounted() {
    setTimeout(() => {
      this.startScrollLogo();
    }, 3000);
  },
  methods: {
    getImgUrl(imgName, imgExtension) {
      var images = null;
      if (imgExtension == "svg") {
        images = require.context("../assets/", false, /\.svg$/);
        return images("./" + imgName + ".svg");
      } else {
        images = require.context("../assets/", false, /\.png$/);
        return images("./" + imgName + ".png");
      }
    },
    visibilityChanged(isVisible) {
      console.log("why us: " + isVisible);
      if (this.shouldScroll != isVisible) {
        this.shouldScroll = isVisible;
        if (isVisible) {
          PageUtils.setShouldNewsLogoScroll(true);
          if (this.scrollId != 0) {
            this.startScrollLogo();
          }
        } else {
          if (this.scrollId != 0) {
            this.stopScrollLogo();
          }
        }
      }
    },
    startScrollLogo() {
      const logoScroll = document.querySelector("#logo-scrolling");
      const mStyle = getComputedStyle(logoScroll);
      if (mStyle.display != "none") {
        // setTimeout(() => {
        //   if (PageUtils.isShouldNewsLogoScroll()) {
        //     logoScroll.scrollTop = logoScroll.scrollTop + 1;
        //   }
        //   this.continueScroll();
        // }, 50);
        this.scrollId = setInterval(() => {
          if (PageUtils.isShouldNewsLogoScroll()) {
            console.log(
              logoScroll.scrollTop +
                " / " +
                (logoScroll.scrollHeight -
                  parseFloat(mStyle.height.replace("px", "")))
            );
            logoScroll.scrollTop = logoScroll.scrollTop + 1;
          }
        }, 50);
      }
    },
    stopScrollLogo() {
      if (this.scrollId != 0) {
        clearInterval(this.scrollId);
      }
    },
    continueScroll() {
      const logoScroll = document.querySelector("#logo-scrolling");
      const mStyle = getComputedStyle(logoScroll);
      if (mStyle.display != "none") {
        if (
          logoScroll.scrollTop >=
          logoScroll.scrollHeight -
            10 -
            parseFloat(mStyle.height.replace("px", ""))
        ) {
          logoScroll.scrollTop = 10;
        }
        setTimeout(() => {
          if (PageUtils.isShouldNewsLogoScroll()) {
            console.log(
              logoScroll.scrollTop +
                " / " +
                (logoScroll.scrollHeight -
                  parseFloat(mStyle.height.replace("px", "")))
            );
            logoScroll.scrollTop = logoScroll.scrollTop + 1;
          }
          this.continueScroll();
        }, 50);
      }
    },
  },
};
</script>
<style scoped>
.news-sharing-container {
  width: 100vw;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;
}
.news-sharing-content {
  width: 88.8vw;
  margin: auto;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
}
.news-sharing-video {
  display: flex;
  flex-direction: column;
  width: 66vw;
  height: 39.5vw;
  margin: 10px;
}
.news-logo-scroll {
  height: 35vw;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  flex: 1;
}
.news-logo-scroll::-webkit-scrollbar {
  display: none;
}
.news-logo-container {
  margin-left: 4vw;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.news-sharing-item {
  width: 9vw;
  margin-bottom: 70px;
}
/* border: solid 1px red; */

.news-logo {
  width: 9vw;
  height: auto;
}
@media (max-width: 845px) {
  .news-sharing-content {
    flex-direction: column;
  }
  .news-sharing-video {
    width: 89vw;
    height: 70vw;
    margin: 0px;
  }
  .news-logo-container {
    width: 90vw;
    flex: none;
    flex-direction: row;
  }
  .news-logo-link {
    display: inline-block;
  }
  .news-logo {
    width: 20vw;
    height: auto;
  }
}
</style>
