<template>
  <div class="footer-container">
    <div class="footer-content">
      <img class="footer-logo" src="../assets/logo_white.svg" />
      <div class="footer-col-info">
        <p class="text-footer-label">
          HỘ KINH DOANH<br />PHÒNG KHÁM CHUYÊN KHOA<br />PHẪU THUẬT THẨM MỸ
          DR.MINH
        </p>
        <ul class="text-footer-content-ul">
          <li>Phụ trách chuyên môn: BS. NGUYỄN ANH MINH</li>
          <li>Số GPHĐ: 07303/HCM-GPHĐ ngày cấp 17/12/2021</li>
          <li>Nơi cấp: Sở Y tế HCM</li>
          <li>Số CCHN: 005374/HCM-CCHN</li>
        </ul>
      </div>
      <div class="footer-col-info">
        <p class="text-footer-label">LIÊN HỆ</p>
        <a
          target="_blank"
          href="https://maps.app.goo.gl/6omWHezU44UGoSR68"
          class="text-footer-content"
        >
          <img src="../assets/ic-pin.svg" /> 1433 Đường 3/2 Phường 16, Quận 11,
          TPHCM
        </a>
        <p class="text-footer-content">
          <img src="../assets/ic-phone.svg" />088 900 36 36
        </p>
        <p class="text-footer-content">
          <img src="../assets/ic-mail.svg" />bacsi.anhminh@gmail.com
        </p>
        <p class="text-footer-content">
          <a target="_blank" href="https://www.facebook.com/bacsinguyenanhminh">
            <img src="../assets/ic-facebook.svg" />
          </a>
          <a
            style="margin-left: 20px; margin-bottom: 3px"
            target="_blank"
            href="https://youtube.com/@drminhaesthetic6269?si=4NIb0wSFLtpK86ie"
          >
            <img src="../assets/ic-youtube.svg" />
          </a>
        </p>
      </div>
      <div class="footer-col-info-else">
        <p class="text-footer-label">GIỜ LÀM VIỆC</p>
        <p class="text-footer-content">18h00 - 22h00 Từ Thứ 2- Thứ 6</p>
        <p class="text-footer-content">08h00 - 22h00 Thứ 7, CN</p>
      </div>
    </div>
    <div class="footer-line"></div>
    <div class="footer-notice show-in-desktop">
      Lưu ý: Kết quả thẩm mỹ phụ thuộc vào đường nét có sẵn và cơ địa của từng
      người | Bản quyền thuộc về&nbsp;
      <a href="https://thammydrminh.com/">https://thammydrminh.com/</a>
    </div>
    <div class="footer-notice show-in-mobile">
      Lưu ý: Kết quả thẩm mỹ phụ thuộc vào<br />
      đường nét có sẵn và cơ địa của từng người <br /><br />
      Bản quyền thuộc về <br />
      <a href="https://thammydrminh.com/">https://thammydrminh.com/</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "GFooter",
  props: {
    msg: String,
  },
};
</script>
<style scoped>
.footer-container {
  width: 100vw;
  background-color: #1d1b1b;
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
}
.footer-content {
  width: 94vw;
  display: flex;
  flex-direction: row;
  margin-left: 5.5vw;
}

.footer-logo {
  width: 11.4vw;
  height: 11.4vw;
  align-self: center;
}
.footer-col-info {
  width: 30%;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}
.footer-col-info a {
  text-decoration: none;
}

.footer-col-info-else {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.footer-line {
  width: 100%;
  height: 1px;
  background-color: #777;
}

.footer-notice {
  width: 100%;
  text-align: center;
  font-family: "Inter Regular";
  font-size: 16px;
  color: #777;
  margin: 5px 0px 15px 0px;
  justify-content: center;
}

.footer-notice a {
  color: #777;
}

.text-footer-label {
  font-family: "Inter Bold";
  font-size: 16px;
  color: white;
  margin: 0px 0px 16px 0px;
  padding: 0px;
}

.text-footer-content {
  font-family: "Inter Regular";
  font-size: 16px;
  margin: 0px 0px 10px 0px;
  color: white;
  padding-left: 0px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.text-footer-content-ul {
  font-family: "Inter Regular";
  font-size: 16px;
  margin: 0px 0px 10px 0px;
  color: white;
  padding-left: 20px;
}

.text-footer-content-ul li {
  margin-bottom: 8px;
}

.text-footer-content img {
  margin-right: 5px;
}
.text-footer-content-ul img {
  margin-right: 5px;
}
@media (max-width: 845px) {
  .footer-container {
    align-items: center;
    padding-bottom: 33vw;
  }
  .footer-content {
    flex-direction: column;
  }
  .footer-logo {
    width: 42vw;
    height: 42vw;
  }
  .footer-col-info {
    width: 87.7vw;
  }
  .footer-col-info-else {
    margin-left: 0px;
    align-items: center;
    text-align: center;
  }
  .text-footer-content {
    padding-left: 0px;
  }
  .text-footer-content-ul {
    padding-left: 10px;
  }
  .text-footer-label {
    margin-left: 0px;
    text-align: center;
  }
  .footer-line {
    width: 95.8vw;
    height: 1px;
    background-color: #777;
  }

  .footer-notice {
    width: 95.8vw;
    display: flex;
    flex-direction: column;
  }
}
</style>
