<template>
  <div
    id="box-appointment"
    @click="iconCloseIsClicked()"
    class="box-appointment-container"
  >
    <div @click.stop="preventClickParent()" class="box-appointment-content">
      <img
        @click.stop="iconCloseIsClicked()"
        id="ic-close-ap-box"
        src="../assets/ic-close-box.png"
      />
      <div id="box-appointment-promotion" class="f-col align-center">
        <p class="text-r20-fixed no-margin">Giảm ngay</p>
        <p class="text-b24-fixed text-gold no-margin">10.000.000VNĐ</p>
        <p class="text-r20-fixed no-margin mb-20">đến hết 31/12/2023</p>
      </div>
      <div id="box-appointment-promotion-short" class="f-col align-center">
        <p class="text-b24">Nhận ưu đãi & Đặt lịch chuyên gia</p>
      </div>
      <div class="box-appointment-form">
        <FormAppointment btnText="Đặt lịch tư vấn 1:1"></FormAppointment>
      </div>
    </div>
  </div>
</template>

<script>
import { PageUtils } from "../PageUtils.ts";
import FormAppointment from "./FormAppointment.vue";
export default {
  name: "BoxAppointment",
  props: {
    msg: String,
  },
  components: {
    FormAppointment,
  },
  methods: {
    iconCloseIsClicked: function () {
      console.log("iconCloseIsClicked");
      PageUtils.hideAppointmentBox();
    },
    preventClickParent: function () {
      console.log("preventClickParent");
    },
  },
};
</script>
<style scoped>
.box-appointment-container {
  width: 100vw;
  height: 100vh;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 200000000;
  background-color: rgba(0, 0, 0, 0.6);
}
.box-appointment-content {
  width: 45%;
  padding: 40px 20px;
  border-radius: 16px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.box-host-profile {
  margin-right: 50px;
  width: 30vw;
  height: auto;
}
#ic-close-ap-box {
  width: 40px;
  height: 40px;
  margin: -30px -10px 0px 0px;
  align-self: flex-end;
  cursor: pointer;
}
.box-appointment-form {
  width: 70%;
}
@media (max-width: 845px) {
  .box-appointment-content {
    width: 90%;
  }
  .box-appointment-form {
    width: 100%;
  }
}
</style>
