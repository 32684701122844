<template>
  <div id="user-sharing" class="user-sharing-container">
    <p class="section-title">Khách hàng trải lòng</p>
    <div v-if="isDesktop" class="user-sharing-grid">
      <div class="user-sharing-above-row">
        <div class="user-sharing-item-large">
          <iframe
            width="auto"
            height="auto"
            class="flex-1"
            src="https://www.youtube.com/embed/ywvbXKo9vqo?si=hGTK2wdGrr6NNFYR"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
          <a
            target="_blank"
            href="https://www.youtube.com/watch?v=ywvbXKo9vqo"
            class="text-video-title"
          >
            Cô gái từng cầu cứu vì mí hỏng nặng
          </a>
        </div>
        <div class="user-sharing-grid-right-col">
          <div class="user-sharing-item">
            <iframe
              width="auto"
              height="auto"
              class="flex-1"
              src="https://www.youtube.com/embed/vIM6YAcpbhw?si=8HN-tO52s9AhhuA1"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
            <a
              target="_blank"
              href="https://www.youtube.com/watch?v=vIM6YAcpbhw"
              class="text-video-title"
            >
              Đã từng tự tử vì cắt mí hỏng</a
            >
          </div>
          <div class="user-sharing-item">
            <iframe
              width="auto"
              height="auto"
              class="flex-1"
              src="https://www.youtube.com/embed/hKWRpGJyBvI?si=epCwnnxMQlX_vT1e"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
            <a
              target="_blank"
              href="https://www.youtube.com/watch?v=hKWRpGJyBvI"
              class="text-video-title"
            >
              Từ Úc về Việt Nam thực hiện Tái phẩu thuật mí hỏng
            </a>
          </div>
        </div>
      </div>
      <div class="user-sharing-below-row">
        <div class="user-sharing-item">
          <iframe
            width="auto"
            height="auto"
            src="https://www.youtube.com/embed/3OxC8kl5rS0?si=J0tMNAns0qPPYW1B"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
        <div class="user-sharing-item">
          <iframe
            width="auto"
            height="auto"
            src="https://www.youtube.com/embed/tb8vXEb04Xw?si=r2e7Qna1iCCM6q3Y"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
        <div class="user-sharing-item">
          <iframe
            width="auto"
            height="auto"
            src="https://www.youtube.com/embed/l7GOMjYLdBA?si=6RdZRnqpRWk0b96l"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
        <div class="user-sharing-item">
          <iframe
            width="auto"
            height="auto"
            src="https://www.youtube.com/embed/OsrzVavp0u8?si=3yiyxRejY8E9G5JY"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
    <div v-else class="user-sharing-grid">
      <div class="user-sharing-item">
        <iframe
          width="auto"
          height="auto"
          class="flex-1"
          src="https://www.youtube.com/embed/ywvbXKo9vqo?si=hGTK2wdGrr6NNFYR"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
        <a
          target="_blank"
          href="https://www.youtube.com/watch?v=ywvbXKo9vqo"
          class="text-video-title"
        >
          Cô gái từng cầu cứu vì mí hỏng nặng
        </a>
      </div>
      <div class="user-sharing-item">
        <iframe
          width="auto"
          height="auto"
          class="flex-1"
          src="https://www.youtube.com/embed/vIM6YAcpbhw?si=8HN-tO52s9AhhuA1"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
        <a
          target="_blank"
          href="https://www.youtube.com/watch?v=vIM6YAcpbhw"
          class="text-video-title"
        >
          Đã từng tự tử vì cắt mí hỏng</a
        >
      </div>
      <div class="user-sharing-item">
        <iframe
          width="auto"
          height="auto"
          class="flex-1"
          src="https://www.youtube.com/embed/hKWRpGJyBvI?si=epCwnnxMQlX_vT1e"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
        <a
          target="_blank"
          href="https://www.youtube.com/watch?v=hKWRpGJyBvI"
          class="text-video-title"
        >
          Từ Úc về Việt Nam thực hiện Tái phẩu thuật mí hỏng
        </a>
      </div>
      <div class="user-sharing-item">
        <iframe
          width="auto"
          height="auto"
          src="https://www.youtube.com/embed/3OxC8kl5rS0?si=J0tMNAns0qPPYW1B"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
      <div class="user-sharing-item">
        <iframe
          width="auto"
          height="auto"
          src="https://www.youtube.com/embed/tb8vXEb04Xw?si=r2e7Qna1iCCM6q3Y"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
      <div class="user-sharing-item">
        <iframe
          width="auto"
          height="auto"
          src="https://www.youtube.com/embed/l7GOMjYLdBA?si=6RdZRnqpRWk0b96l"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
      <div class="user-sharing-item">
        <iframe
          width="auto"
          height="auto"
          src="https://www.youtube.com/embed/OsrzVavp0u8?si=3yiyxRejY8E9G5JY"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
    </div>
    <p class="text-r24-fixed no-margin hiding-temp">Giảm ngay</p>
    <p class="text-b32 text-gold no-margin hiding-temp">10.000.000VNĐ</p>
    <p class="text-r24-fixed no-margin mb-20 hiding-temp">đến hết 31/12/2023</p>
    <div class="user-sharing-form hiding-temp">
      <FormAppointment btnText="Đặt lịch tư vấn 1:1"></FormAppointment>
    </div>
  </div>
</template>

<script>
import FormAppointment from "./FormAppointment.vue";
export default {
  components: { FormAppointment },
  name: "UserSharing",
  props: {
    msg: String,
  },
  data() {
    return {
      isDesktop: true,
    };
  },
  mounted() {
    if (window.innerWidth < 845) {
      this.isDesktop = false;
    }
  },
};
</script>
<style scoped>
.user-sharing-container {
  width: 100vw;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;
}
.user-sharing-grid {
  width: calc(100vw - 160px);
  display: flex;
  flex-direction: column;
  margin: 20px 80px 20px 80px;
}

.user-sharing-above-row {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.user-sharing-grid-right-col {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.user-sharing-grid-right-col div {
  width: 100%;
  margin: 0px 10px 0px 0px;
}
.user-sharing-item-large {
  width: calc(75% - 20px);
  height: 39.5vw;
  margin: 0px 20px 10px 0px;
  display: flex;
  flex-direction: column;
}
.user-sharing-below-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.user-sharing-item {
  width: calc(25% - 20px);
  margin: 0px 10px 0px 0px;
  display: flex;
  flex-direction: column;
}

.user-sharing-item iframe {
  width: 100%;
}
.user-sharing-form {
  width: 35%;
}
@media (max-width: 845px) {
  .user-sharing-grid {
    width: 100vw;
    height: 60vw;
    display: flex;
    flex-direction: row;
    scroll-behavior: smooth;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .user-sharing-grid::-webkit-scrollbar {
    display: none;
  }
  .user-sharing-item {
    width: 77vw;
    height: 43vw;
    margin: 0px 20px 0px 0px;
  }

  .user-sharing-item iframe {
    width: 77vw;
  }
  .user-sharing-form {
    width: 100%;
  }
}
</style>
