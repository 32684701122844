<template>
  <div id="hero" class="top-hero-container f-col">
    <div class="flex-1"></div>
    <img class="curve show-in-desktop" src="../assets/curve-hero.png" />
    <img class="curve show-in-mobile" src="../assets/curve-hero-m.png" />
    <a @click="startScroll('promotion')" class="click-area-hero pointer"></a>
  </div>
</template>

<script>
import { PageUtils } from "../PageUtils.ts";
export default {
  name: "TopHero",
  props: {
    msg: String,
  },
  methods: {
    startScroll: function (id) {
      PageUtils.setShouldNewsLogoScroll(false);
      console.log("id: " + id);
      document.getElementById(id).scrollIntoView();
    },
  },
};
</script>
<style scoped>
.top-hero-container {
  width: 100vw;
  height: 56vw;
  background-image: url("../assets/bg-top-hero.png");
  background-size: contain;
}
.click-area-hero {
  display: flex;
  position: absolute;
  width: 18vw;
  height: 4vw;
  left: 13vw;
  top: 42vw;
}
@media (max-width: 845px) {
  .top-hero-container {
    width: 100vw;
    height: 139vw;
    background-image: url("../assets/bg-top-hero-m.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
  .click-area-hero {
    display: none;
  }
}
</style>
