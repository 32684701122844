<template>
  <div id="box-promotion" class="box-promotion-container show-in-desktop">
    <div @click="$emit('preventParentClick')" class="box-promotion-content">
      <img
        @click="$emit('closePromotion')"
        id="ic-close-box"
        src="../assets/ic-close-box.png"
      />
      <div class="f-row align-center">
        <img
          class="box-host-profile"
          src="../assets/pop-up-promotion.jpeg"
          loading="lazy"
        />
        <div class="f-col align-center" style="width: 30vw">
          <!-- <p class="text-r24 no-margin">
            Giảm ngay <span class="text-gold text-b32">10.000.000 đ</span>
          </p>
          <p class="text-r24 no-margin">chỉ áp dụng trong tháng 11/2024</p> -->
          <p class="text-b24">Tôi muốn được tư vấn 1:1</p>
          <FormAppointment btnText="Đặt lịch tư vấn 1:1"></FormAppointment>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormAppointment from "./FormAppointment.vue";
export default {
  name: "BoxPromotion",
  props: {
    msg: String,
  },
  components: {
    FormAppointment,
  },
};
</script>
<style scoped>
.box-promotion-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 2000;
  background-color: rgba(0, 0, 0, 0.6);
}
.box-promotion-content {
  width: 65%;
  padding: 40px 20px;
  border-radius: 16px;
  background-color: white;
  display: flex;
  flex-direction: column;
}
.box-host-profile {
  margin-right: 50px;
  width: 30vw;
  height: 30vw;
  border-radius: 16px;
}
#ic-close-box {
  width: 40px;
  height: 40px;
  margin: -30px -10px 0px 0px;
  align-self: flex-end;
  cursor: pointer;
}
</style>
