<template>
  <div class="f-col header-container">
    <div class="f-row">
      <a class="pointer" @click="startScroll('hero')">
        <img
          id="header-main-logo"
          src="../assets/logo.png"
          class="header-logo"
        />
      </a>
      <div class="nav-container">
        <a @click="startScroll('promotion')" class="text-b20 pointer"
          >Nhận ưu đãi</a
        >
        <a @click="startScroll('seven-signs')" class="text-b20 pointer"
          >7 dấu hiệu mí hỏng</a
        >
        <a @click="startScroll('treatment-way')" class="text-b20 pointer"
          >Hành trình chữa lành</a
        >
        <a @click="startScroll('user-sharing')" class="text-b20 pointer"
          >Khách hàng trải lòng</a
        >
        <p @click="showAppointmentBox()" class="btn-primary">
          <span>Đặt lịch chuyên gia</span>
        </p>
      </div>
      <div class="promotion-container">
        <p class="text-header-promotion">
          Giảm ngay <span class="text-b20 text-gold">10 triệu đồng</span
          ><br />đến hết 31/12/2023
        </p>
        <p
          @click="showAppointmentBox()"
          class="btn-primary mr-10 opacity-in-mobile"
        >
          Đặt lịch
        </p>
      </div>
    </div>
    <img
      id="header-curve"
      src="../assets/curve-header.png"
      class="base-img curve"
    />
  </div>
</template>

<script>
import { PageUtils } from "../PageUtils.ts";
export default {
  name: "GHeader",
  props: {
    msg: String,
  },
  data() {
    return {
      pagePositionY: 0,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handlePageScroll);
  },
  methods: {
    startScroll: function (id) {
      PageUtils.setShouldNewsLogoScroll(false);
      console.log("id: " + id);
      document.getElementById(id).scrollIntoView();
    },
    showAppointmentBox: function () {
      PageUtils.showAppointmentBox();
    },
    handlePageScroll: function () {
      console.log("handlePageScroll: " + window.innerWidth);
      this.pagePositionY = window.scrollY;
      let logoHeader = document.getElementById("header-main-logo");
      let curve = document.getElementById("header-curve");
      if (this.pagePositionY > 300) {
        if (window.innerWidth < 845) {
          logoHeader.style.width = "60px";
        } else {
          logoHeader.style.width = "60px";
        }
        curve.style.display = "none";
      } else {
        if (window.innerWidth < 845) {
          logoHeader.style.width = "16.5vw";
        } else {
          logoHeader.style.width = "10vw";
        }
        curve.style.display = "block";
      }
    },
  },
};
</script>
<style scoped>
.header-container {
  width: 100vw;
  position: fixed;
  top: 0px;
  z-index: 1000;
}
.nav-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 60px;
  padding-left: 14vw;
  padding-right: 15px;
  background: white;
}

.nav-container a {
  text-decoration: none;
  color: var(--color-primary);
}

.nav-container a:hover {
  color: #208aa6;
}

.promotion-container {
  display: none;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
  height: 90px;
  background: white;
}

.header-logo {
  width: 10vw;
  height: auto;
  left: 80px;
  position: absolute;
  transition: 0.4s;
}

@media (max-width: 845px) {
  .nav-container {
    display: none;
  }
  .promotion-container {
    display: flex;
    padding-left: 16.5vw;
  }
  .text-header-promotion {
    flex: 1;
    font-family: "Inter Regular";
    font-size: 14px;
    text-align: center;
    margin-right: -10px;
    margin-top: 20px;
  }
  .header-logo {
    width: 16.5vw;
    height: auto;
    left: 15px;
    top: 17px;
    position: absolute;
  }
}
</style>
