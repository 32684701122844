<template>
  <div class="main-container f-col">
    <TopHero></TopHero>
    <div class="section-promotion">
      <SectionPromotion></SectionPromotion>
    </div>
    <HostProfile></HostProfile>
    <div v-if="false" class="show-in-mobile bg-white w-100">
      <FormAppointment btnText="Đăng ký tư vấn"></FormAppointment>
    </div>
    <SevenSigns></SevenSigns>
    <FourLevels></FourLevels>
    <HealsWay></HealsWay>
    <UserStory></UserStory>
    <UserFeedback></UserFeedback>
    <UserSharing></UserSharing>
    <TheNumbers></TheNumbers>
    <WhyUs></WhyUs>
    <TreatmentProcess></TreatmentProcess>
    <NewsSharing></NewsSharing>
    <MakeAppointment></MakeAppointment>
  </div>
</template>

<script>
import TopHero from "./TopHero.vue";
import SectionPromotion from "./SectionPromotion.vue";
import HostProfile from "./HostProfile.vue";
import SevenSigns from "./SevenSigns.vue";
import FourLevels from "./FourLevels.vue";
import HealsWay from "./HealsWay.vue";
import UserStory from "./UserStory.vue";
import UserFeedback from "./UserFeedback.vue";
import UserSharing from "./UserSharing.vue";
import TheNumbers from "./TheNumbers.vue";
import WhyUs from "./WhyUs.vue";
import TreatmentProcess from "./TreatmentProcess.vue";
import NewsSharing from "./NewsSharing.vue";
import MakeAppointment from "./MakeAppointment.vue";
import FormAppointment from "./FormAppointment.vue";
export default {
  name: "MainContent",
  props: {
    msg: String,
  },
  components: {
    TopHero,
    SectionPromotion,
    HostProfile,
    SevenSigns,
    FourLevels,
    HealsWay,
    UserStory,
    UserFeedback,
    UserSharing,
    TheNumbers,
    WhyUs,
    TreatmentProcess,
    NewsSharing,
    MakeAppointment,
    FormAppointment,
  },
};
</script>
<style scoped>
.main-container {
  width: 100vw;
  margin-top: 60px;
}
.section-promotion {
  display: flex;
}
@media (max-width: 845px) {
  .section-promotion {
    display: none;
    height: 0;
  }
}
</style>
