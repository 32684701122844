<template>
  <div class="make-appointment-container">
    <img class="curve" src="../assets/curve-appoint-t.png" />
    <div class="make-appointment-content">
      <p class="section-title text-white">
        Đăng ký và đặt lịch hẹn<br />tư vấn thẩm mỹ ngay hôm nay! <br />
      </p>
      <div class="make-appointment-form-container">
        <!-- <p class="make-appointment-explain">
          Việc đăng ký thông tin và đặt lịch hẹn tư vấn thẩm mỹ chỉ là một bước
          nhỏ trong quá trình chăm sóc sắc đẹp của bạn, nhưng nó có thể mang lại
          những thay đổi tích cực đáng kể cho ngoại hình và tự tin của bạn.
        </p> -->
        <p class="make-appointment-text-description text-gold-gradient">
          Vài bước nhỏ cho <br class="show-in-desktop" />
          những thay đổi lớn
        </p>
        <div class="make-appointment-form">
          <FormAppointment btnText="Đặt lịch tư vấn 1:1"></FormAppointment>
        </div>
      </div>
    </div>
    <img class="curve show-in-desktop" src="../assets/curve-appoint-b.png" />
  </div>
</template>

<script>
import FormAppointment from "./FormAppointment.vue";
export default {
  components: { FormAppointment },
  name: "MakeAppointment",
  props: {
    msg: String,
  },
};
</script>
<style scoped>
.make-appointment-container {
  width: 100vw;
  height: 50.2vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url("../assets/bg-section-appoint.jpeg") no-repeat;
  background-size: cover;
  background-position: center;
}
.make-appointment-content {
  flex: 1;
}
.make-appointment-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.make-appointment-explain {
  width: 30vw;
  font-family: "Inter Regular";
  font-size: 24px;
  color: white;
  text-align: justify;
  margin: 0px 80px 0px 0px;
}
.make-appointment-text-description {
  color: #e7b00c;
  font-family: "Inter Bold";
  font-size: 24px;
  text-align: center;
}
.make-appointment-form {
  width: 75%;
}
@media (max-width: 845px) {
  .make-appointment-container {
    width: 100vw;
    height: 170vw;
  }
  .make-appointment-content {
    width: 99vw;
  }
  .make-appointment-form-container {
    flex-direction: column;
  }
  .make-appointment-explain {
    width: 95.8vw;
    margin: 2vw;
    font-size: 14px;
    text-align: center;
  }
  .make-appointment-text-description {
    font-size: 16px;
  }
  .make-appointment-form {
    width: 96%;
    margin: 0px 2%;
  }
}
</style>
