<template>
  <div class="four-levels-container">
    <img class="curve" src="../assets/curve-4-level-t.png" />
    <!-- <img
      class="img-full-width"
      src="../assets/bg-section-4-level.png"
      loading="lazy"
    /> -->
    <div class="four-levels-content">
      <div class="four-levels-content-inner">
        <div class="four-levels-item">
          <iframe
            width="100%"
            height="auto"
            src="https://www.youtube.com/embed/sNmc7H4v18k?si=7TXlegfjatduzDbz"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
          <a
            target="_blank"
            href="https://www.youtube.com/watch?v=sNmc7H4v18k"
            class="text-video-levels text-white text-center"
          >
            Cấp độ 1
          </a>
        </div>
        <div class="four-levels-item">
          <iframe
            width="100%"
            height="auto"
            src="https://www.youtube.com/embed/ZaZWWYZvqAM?si=Apu-iPoZvO1OibEh"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
          <a
            target="_blank"
            href="https://www.youtube.com/watch?v=ZaZWWYZvqAM"
            class="text-video-levels text-white text-center"
          >
            Cấp độ 2
          </a>
        </div>
        <div class="four-levels-item">
          <iframe
            width="100%"
            height="auto"
            src="https://www.youtube.com/embed/8S9Gc7s-7pk?si=MCDewlpzCm2TMee4"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
          <a
            target="_blank"
            href="https://www.youtube.com/watch?v=8S9Gc7s-7pk"
            class="text-video-levels text-white text-center"
          >
            Cấp độ 3
          </a>
        </div>
        <div class="four-levels-item">
          <iframe
            width="100%"
            height="auto"
            src="https://www.youtube.com/embed/-blZh48D5e0?si=dK8KJFIqruuNdkWD"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
          <a
            target="_blank"
            href="https://www.youtube.com/watch?v=-blZh48D5e0"
            class="text-video-levels text-white text-center"
          >
            Cấp độ 4
          </a>
        </div>
      </div>
    </div>
    <img class="curve" src="../assets/curve-4-level-b.png" />
  </div>
</template>

<script>
export default {
  name: "FourLevels",
  props: {
    msg: String,
  },
};
</script>
<style scoped>
.four-levels-container {
  width: 100vw;
  height: 108vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;
  background-color: white;
}
.four-levels-content {
  width: 100vw;
  height: 100vw;
  background-image: url("../assets/bg-section-4-level.png");
  background-size: 100vw 100vw;
}
.four-levels-content-inner {
  width: calc(100vw - 160px);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 21vw 0px 0px 80px;
}
.four-levels-item {
  width: calc((100vw - 240px) / 2);
  height: 500px;
  display: flex;
  flex-direction: column;
  margin: 0px 40px 40px 0px;
}
.four-levels-item iframe {
  height: 450px;
}
.text-video-levels {
  font-family: "Inter Bold";
  font-size: 20px;
  text-decoration: none;
  margin: 15px 0px 0px 0px;
  color: white;
}
.text-video-levels:visited {
  color: white;
}
@media (max-width: 845px) {
  .four-levels-content-inner {
    width: calc(100vw - 20px);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 23vw 0px 0px 20px;
  }
  .four-levels-item {
    width: calc((100vw - 60px) / 2);
    height: 130px;
    display: flex;
    flex-direction: column;
    margin: 0px 15px 20px 0px;
  }
  .four-levels-item iframe {
    height: 100px;
  }
  .text-video-levels {
    font-family: "Inter Bold";
    font-size: 20px;
    text-decoration: none;
    margin: 15px 0px 0px 0px;
    color: white;
  }

  .text-video-levels {
    font-size: 16px;
    margin: 10px 0px 0px 0px;
  }
}
</style>
