<template>
  <div class="host-profile-container f-row">
    <div class="profile-slide-container">
      <Carousel :autoplay="5000" wrap-around="true" :items-to-show="1">
        <slide :key="1">
          <div class="edu-img-full">
            <img src="../assets/slide-host-profile-1.jpg" loading="lazy" />
          </div>
        </slide>
        <slide :key="2">
          <div class="edu-img-full">
            <img src="../assets/slide-host-profile-2.jpg" loading="lazy" />
          </div>
        </slide>
        <slide :key="3">
          <div class="edu-img-full">
            <img src="../assets/slide-host-profile-3.jpg" loading="lazy" />
          </div>
        </slide>
        <template #addons>
          <Pagination />
        </template>
      </Carousel>
    </div>
    <div v-if="false" class="host-profile-col-left">
      <img src="../assets/drminh-img-around.png" loading="lazy" />
      <p class="text-r20">
        ThS. BS Nguyễn Anh Minh đã trải qua quá trình tu nghiệp chuyên sâu về
        thẩm mỹ tại các đơn vị uy tín trong & ngoài nước ( Hòa Kỳ, Hàn Quốc)
      </p>
      <div class="edu-slide-container">
        <Carousel :autoplay="2000" wrap-around="true" :items-to-show="1">
          <slide :key="1">
            <div class="edu-img">
              <img src="../assets/edu-dh-y-ha-noi.png" loading="lazy" />
            </div>
          </slide>
          <slide :key="2">
            <div class="edu-img">
              <img src="../assets/edu-dh-t-hoa-ky.png" loading="lazy" />
            </div>
          </slide>
          <slide :key="3">
            <div class="edu-img">
              <img src="../assets/edu-bv-han-quoc.png" loading="lazy" />
            </div>
          </slide>
          <template #addons>
            <Pagination />
          </template>
        </Carousel>
      </div>
    </div>
    <div v-if="false" lass="host-profile-col-right">
      <div class="f-col show-in-desktop">
        <p class="text-b24">
          Chuyên gia Tái phẫu thuật mí hỏng - EYELID REVISION
        </p>
        <p class="text-b32">Thạc sĩ – Bác sĩ Nguyễn Anh Minh</p>
        <p class="text-r20">Giám đốc chuyên môn Viện Thẩm Mỹ Y Khoa Dr.Minh</p>
        <p class="text-r16 text-black">
          Là chuyên gia trong lĩnh vực thẩm mỹ, đặc biệt là
          <span class="text-b20">Tái phẫu thuật</span> mí hỏng đã
          <span class="text-b20">giải cứu thành công hơn 12.000 ca</span>
          mí hỏng/lỗi mọi mức độ. Với hơn
          <span class="text-b20">15 năm kinh nghiệm</span>, bác sĩ từng đảm
          nhiệm các cương vị:
        </p>
        <ul class="text-r16 text-black">
          <li>Bác sĩ Ngoại khoa BV nhân dân 115</li>
          <li>Nguyên Trưởng phân khoa Tạo hình thẩm mỹ BV nhân dân 115</li>
          <li>Nguyên Trưởng khoa Tạo hình thẩm mỹ mắt BV thẩm mỹ Hàn Quốc</li>
          <li>Nguyên Giám đốc chuyên môn Viện thẩm mỹ Plasma</li>
        </ul>
        <p class="text-r20">Bằng cấp chuyên môn</p>
        <div class="certificate-cont">
          <img
            class="el-animation1-scale"
            src="../assets/cer-01.jpeg"
            loading="lazy"
          />
          <img
            class="el-animation1-scale"
            src="../assets/cer-02.jpeg"
            loading="lazy"
          />
          <img
            class="el-animation1-scale"
            src="../assets/cer-03.jpeg"
            loading="lazy"
          />
          <img
            class="el-animation1-scale"
            src="../assets/cer-04.jpeg"
            loading="lazy"
          />
          <img
            class="el-animation1-scale"
            src="../assets/cer-05.jpeg"
            loading="lazy"
          />
          <img
            class="el-animation1-scale"
            src="../assets/cer-06.jpeg"
            loading="lazy"
          />
          <img
            class="el-animation1-scale"
            src="../assets/cer-07.jpeg"
            loading="lazy"
          />
          <img
            class="el-animation1-scale"
            src="../assets/cer-08.jpeg"
            loading="lazy"
          />
        </div>
      </div>
      <div class="f-col show-in-mobile">
        <p class="text-r16 text-black">
          <span class="text-b16 text-primary"
            >Giải cứu thành công hơn 12.000 ca</span
          >
          mí hỏng/lỗi mọi mức độ. Với hơn
          <span class="text-b16 text-primary">15 năm kinh nghiệm</span>, bác sĩ
          từng đảm nhiệm các cương vị:
        </p>
        <ul class="text-r16 text-black no-margin" style="padding-left: 15px">
          <li>Bác sĩ Ngoại khoa BV nhân dân 115</li>
          <li>Nguyên Trưởng phân khoa Tạo hình thẩm mỹ BV nhân dân 115</li>
          <li>Nguyên Trưởng khoa Tạo hình thẩm mỹ mắt BV thẩm mỹ Hàn Quốc</li>
          <li>Nguyên Giám đốc chuyên môn Viện thẩm mỹ Plasma</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide, Pagination } from "vue3-carousel";
export default {
  name: "HostProfile",
  props: {
    msg: String,
  },
  components: {
    Carousel,
    Slide,
    Pagination,
  },
};
</script>
<style scoped>
.host-profile-container {
  width: 100vw;
  background: white;
}

.host-profile-col-left {
  width: 36vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 80px;
}

.host-profile-col-right {
  flex: 1;
  margin: 0px 80px 0px 50px;
}
.certificate-cont {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.host-profile-col-right p {
  width: 100%;
  word-wrap: break-word;
}
.edu-slide-container {
  width: 29.3vw;
}
.edu-img {
  width: 29.3vw;
  height: auto;
}
.profile-slide-container {
  width: calc(100vw - 160px);
  margin: 20px 80px;
}
.edu-img-full {
  width: calc(100vw - 160px);
  height: auto;
}
.edu-img-full img {
  width: calc(100vw - 160px);
  height: auto;
}

.certificate-cont img {
  width: calc(25% - 30px);
  /* height: auto; */
  margin: 15px 30px 15px 0px;
}

@media (max-width: 845px) {
  .host-profile-col-left {
    display: none;
  }

  .host-profile-col-right {
    width: auto;
    margin: 0px 10px 0px 10px;
  }
  .profile-slide-container {
    width: calc(100vw - 20px);
    margin: 20px 10px;
  }
  .edu-img-full {
    width: calc(100vw - 20px);
    height: auto;
  }
  .edu-img-full img {
    width: calc(100vw - 20px);
    height: auto;
  }
}
</style>
