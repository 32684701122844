<template>
  <div id="seven-signs" class="seven-signs-container">
    <p class="seven-signs-title">7 dấu hiệu cắt mí bị hỏng</p>
    <div class="seven-signs-content">
      <p class="text-r24 text-center">
        Rất nhiều người đã tìm đến phẫu thuật thẩm mỹ mà điển hình là cắt mí mắt
        nhằm tạo thêm phần lôi cuốn cho “cửa sổ tâm hồn”. Tuy nhiên, phương pháp
        này vẫn tiềm ẩn nhiều rủi ro khó lường nếu thực hiện không đúng.
      </p>
      <div class="seven-signs-video">
        <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/Yxg2ydFrmTU?si=KqYtdvnaozwmIcVZ"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
      <a
        target="_blank"
        href="https://www.youtube.com/watch?v=Yxg2ydFrmTU"
        class="text-video-title"
      >
        7 dấu hiệu giúp bạn nhận biết cắt mí bị hỏng, nếu gặp phải cần tới ngay
        cơ sở thẩm mỹ để được cứu chữa kịp thời !!!
      </a>
      <p class="text-r24-fixed no-margin hiding-temp">Giảm ngay</p>
      <p class="text-b32 text-gold no-margin hiding-temp">10.000.000VNĐ</p>
      <p class="text-r24-fixed no-margin mb-20 hiding-temp">
        đến hết 31/12/2023
      </p>
      <div class="seven-signs-form hiding-temp">
        <FormAppointment btnText="Đặt lịch tư vấn 1:1"></FormAppointment>
      </div>
    </div>
  </div>
</template>

<script>
import FormAppointment from "./FormAppointment.vue";
export default {
  name: "SevenSigns",
  props: {
    msg: String,
  },
  components: {
    FormAppointment,
  },
};
</script>
<style scoped>
.seven-signs-container {
  width: 100vw;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;
}
.seven-signs-title {
  width: 58.8vw;
  height: 5.6vw;
  line-height: 5.6vw;
  font-family: "Inter Bold";
  font-size: 40px;
  color: white;
  background-image: url("../assets/bg-07-signs.png");
  background-size: 58.8vw 5.6vw;
  background-repeat: no-repeat;
  text-align: center;
}
.seven-signs-content {
  width: 58.8vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.seven-signs-video {
  width: 100%;
  height: 500px;
}
.seven-signs-form {
  width: 55%;
}
@media (max-width: 845px) {
  .seven-signs-container {
    padding-bottom: 10px;
    padding-top: 20px;
  }
  .seven-signs-title {
    width: 95.6vw;
    height: 56px;
    font-family: "Inter Bold";
    font-size: 22px;
    line-height: 56px;
    background-size: 95.6vw 56px;
    margin: 0px 16px 0px 16px;
  }
  .seven-signs-form {
    width: 100%;
  }
  .seven-signs-content {
    width: 95.6vw;
    text-align: center;
  }
  .seven-signs-video {
    width: 100%;
    height: 200px;
  }
}
</style>
