<template>
  <div id="treatment-way" class="heals-way-container">
    <p class="text-r32 no-margin">Hành trình chữa lành</p>
    <p class="show-in-desktop heals-way-title">
      Hồi sinh vẻ đẹp - Hồi sinh cuộc đời
    </p>
    <p class="show-in-mobile heals-way-title">
      Hồi sinh vẻ đẹp<br />Hồi sinh cuộc đời
    </p>
    <p class="heals-way-intro">
      “Đã từng muốn tự tử vì cắt mí hỏng từ nơi kém chất lượng....nhưng mọi thứ
      thay đổi hoàn toàn khi tôi biết đến Dr.Minh.....”
    </p>
    <div class="video-row">
      <div class="video-container">
        <iframe
          width="auto"
          height="auto"
          src="https://www.youtube.com/embed/vIM6YAcpbhw?si=ekOTPWbE_6PO7PRb"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
        <a
          target="_blank"
          href="https://www.youtube.com/watch?v=vIM6YAcpbhw"
          class="text-video-title-fixed"
        >
          Hồi sinh cuộc đời mới - Hào quang trở lại
        </a>
      </div>
      <div class="video-container">
        <iframe
          width="auto"
          height="auto"
          src="https://www.youtube.com/embed/hKWRpGJyBvI?si=SqksmeQhXHITSiJ9"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
        <a
          target="_blank"
          href="https://www.youtube.com/watch?v=hKWRpGJyBvI"
          class="text-video-title-fixed"
        >
          Việt kiều Úc - Vạn dặm đi tìm vẻ đẹp
        </a>
      </div>
      <div class="video-container">
        <iframe
          width="auto"
          height="auto"
          src="https://www.youtube.com/embed/j6QmzU5JbKU?si=qwfY6tcjIrM37cXN"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
        <a
          target="_blank"
          href="https://www.youtube.com/watch?v=j6QmzU5JbKU"
          class="text-video-title-fixed"
        >
          Nỗi đau khi niềm tin đặt nhằm chỗ</a
        >
      </div>
      <div class="video-container">
        <iframe
          width="auto"
          height="auto"
          src="https://www.youtube.com/embed/OsrzVavp0u8?si=9Ywoo5XbdugG--98"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
        <a
          target="_blank"
          href="https://www.youtube.com/watch?v=OsrzVavp0u8"
          class="text-video-title-fixed"
        >
          Nổi ám ảnh mang tên mí Ấn Độ
        </a>
      </div>
    </div>
    <p class="text-r24 text-content">
      Không chỉ tập trung vào việc sửa mí, chúng tôi còn đặc biệt quan tâm đến
      nhu cầu và mong muốn của từng khách hàng. Chuyên gia tái phẫu thuật
      Dr.Minh sẽ lắng nghe và tư vấn kỹ càng để đảm bảo rằng kết quả cuối cùng
      sẽ phù hợp với mong đợi của bạn.
    </p>
    <p class="text-r24 text-content">
      Hãy để chuyên gia tái phẫu thuật mí hỏng Dr.Minh giúp bạn khắc phục những
      vấn đề về mí mắt và trở lại với vẻ đẹp tự nhiên của mình. Liên hệ với ngay
      hôm nay để biết thêm thông tin về dịch vụ sửa mí hỏng và đặt lịch hẹn!
    </p>
    <div class="heals-way-form hiding-temp">
      <FormAppointment btnText="Đặt lịch tư vấn 1:1"></FormAppointment>
    </div>
  </div>
</template>

<script>
import FormAppointment from "./FormAppointment.vue";
export default {
  name: "HealsWay",
  props: {
    msg: String,
  },
  components: {
    FormAppointment,
  },
};
</script>
<style scoped>
.heals-way-container {
  width: 100vw;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;
}
.heals-way-title {
  width: 58.8vw;
  height: 5.6vw;
  font-family: "Inter Bold";
  font-size: 38px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  line-height: 5.6vw;
  color: white;
  background-image: url("../assets/bg-07-signs.png");
  background-size: 58.8vw 5.6vw;
  background-repeat: no-repeat;
}
.heals-way-intro {
  font-family: "Inter Regular";
  font-size: 24px;
  width: 65%;
  text-align: center;
}
.heals-way-content {
  width: 58.8vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.video-row {
  width: calc(100vw - 160px);
  display: flex;
  flex-direction: row;
  margin-left: 80px;
  margin-right: 80px;
}
.video-container {
  width: calc(25% - 20px);
  height: auto;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
}
.text-content {
  text-align: justify;
  display: block;
  margin-left: 80px;
  margin-right: 80px;
}
.heals-way-form {
  width: 35%;
}
@media (max-width: 845px) {
  .heals-way-title {
    width: 95.8vw;
    height: 21vw;
    line-height: 8vw;
    font-family: "Inter Bold";
    font-size: 24px;
    color: white;
    background-size: 95.8vw 21vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
  }
  .heals-way-intro {
    width: 94%;
    font-family: "Inter Regular";
    font-size: 16px;
  }
  .video-row {
    width: 100vw;
    display: flex;
    flex-direction: row;
    margin: 0px 10px;
    overflow-x: auto !important;
    overflow-y: hidden;
  }
  .video-container {
    width: calc(100vw - 20px);
    height: auto;
    /* display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-right: 10px; */
  }
  .text-content {
    margin: 10px;
    font-family: "Inter Regular";
    font-size: 16px;
  }
  .heals-way-form {
    width: 100%;
  }
}
</style>
