<template>
  <iframe name="dummyframe" id="dummyframe" style="display: none"></iframe>
  <form
    target="dummyframe"
    @submit="onFormSubmit"
    method="post"
    action="https://script.google.com/macros/s/AKfycbzc3ilYOMekvFo1Dd2zjUxp-fGHYGjxhvTCS24RN6NchBhxK97jZr0QvBfLnbNQ7eFwLg/exec"
    id="form-appointment"
    class="form-appointment-container"
  >
    <input
      id="form-name"
      name="CustomerName"
      class="t-input-long text-r16"
      type="text"
      required
      oninvalid="this.setCustomValidity('Bạn chưa điền đủ thông tin')"
      placeholder="Nhập họ & tên"
    />
    <input
      id="form-phone"
      name="CustomerPhone"
      class="t-input-long text-r16"
      type="number"
      required
      oninvalid="this.setCustomValidity('Bạn chưa điền đủ thông tin')"
      placeholder="Nhập số điện thoại"
    />
    <input name="SubmitTime" type="hidden" :value="cTime" />
    <p class="text-question">
      Dr.Minh có thể tư vấn cho bạn vào khung thời gian nào?
    </p>
    <div class="form-time">
      <label class="t-input-short-cont">
        <input
          id="input-short-date"
          name="DatePossible"
          class="text-r16"
          type="datetime-local"
          required
          oninvalid="this.setCustomValidity('Bạn chưa điền đủ thông tin')"
          onfocus="'showPicker' in this && this.showPicker()"
          placeholder="Chọn ngày hẹn"
        />
        <!-- <img src="../assets/ic-calendar.svg" /> -->
      </label>
      <!-- <div class="fake-distance show-in-desktop"></div> -->
      <!-- <label class="t-input-short-cont">
        <input
          id="input-short-time"
          name="TimePossible"
          class="text-r16"
          type="time"
          onfocus="'showPicker' in this && this.showPicker()"
          placeholder="Hẹn vào giờ nào?"
        />
      </label> -->
    </div>
    <input type="hidden" name="SubmitTime" id="form-submit-time" />
    <button type="submit" class="form-btn">
      <span>{{ btnText }}</span>
    </button>
    <!-- <input type="submit" value="Submit" /> -->
  </form>
</template>

<script>
export default {
  name: "FormAppointment",
  props: {
    btnText: String,
  },
  data() {
    return {
      name: "",
      phone: "",
      date: "",
      time: "",
      cTime: new Date().toLocaleString(),
    };
  },
  methods: {
    submitAppointment: function () {
      console.log("submitAppointment");
      let form = document.getElementById("form-appointment");
      let elCreated = document.getElementById("form-submit-time");
      let elName = document.getElementById("form-name");
      let elPhone = document.getElementById("form-phone");
      let elDate = document.getElementById("input-short-date");
      let elTime = document.getElementById("input-short-time");
      console.log(this.phone);
      elName.value = this.name;
      elPhone.value = this.phone;
      elDate.value = this.date;
      elTime.value = this.time;
      elCreated.value = new Date().toString();
      form.submit();
    },
    onFormSubmit: function () {
      // event.preventDefault();
      console.log("onFormSubmit");
      setTimeout(() => {
        alert(
          "Thông tin đã được chuyển đến bộ phận Chăm sóc khách hàng. Chúng tôi sẽ liên lạc theo thời gian phù hợp với bạn."
        );
      }, 1500);
    },
  },
};
</script>
<style scoped>
.form-appointment-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#input-short-date {
  width: 100% !important;
  background-color: transparent !important;
}

#input-short-time {
  width: 100% !important;
  background-color: transparent !important;
}

#input-short-date::-webkit-calendar-picker-indicator {
  background: url("../assets/ic-calendar.svg") no-repeat;
  width: 24px;
  height: 24px;
}

#input-short-time::-webkit-calendar-picker-indicator {
  background: url("../assets/ic-clock.svg") no-repeat;
  width: 24px;
  height: 24px;
}

.t-input-long {
  width: calc(100% - 32px);
  border-radius: 8px;
  padding: 12px 16px;
  border: solid 1px #777777;
  margin-bottom: 20px;
  color: #777777;
}
.fake-distance {
  width: 5%;
  height: 10px;
}
.t-input-short-cont {
  /* width: calc(47.5% - 16px); */
  width: 100%;
  border-radius: 8px;
  border: solid 1px #777777;
  padding: 0px 8px;
  color: #777777;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
}

.t-input-short-cont input {
  width: calc(100% - 24px);
  display: inline-block;
  border: none;
  outline: none;
  /* padding: 12px 16px; */
  margin: 12px 0px 12px 0px;
}

.t-input-short-cont img {
  width: 24px;
  height: 24px;
}

.text-question {
  overflow: visible;
  display: block;
  color: #30d2fc !important;
  text-align: center;
  margin: 0px 0px 20px 0px;
  padding: 0px;
  font-family: "Inter Bold";
  font-size: 16px;
}
.form-time {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.form-btn {
  padding: 12px 24px;
  border-radius: 1000px;
  border: solid 1px #e7b00c;
  background-color: #146175;
  color: #e7b00c;
  cursor: pointer;
  font-family: "Inter Bold";
  font-size: 20px;
  margin-top: 20px;
  text-align: center;
}
.form-btn span {
  background: linear-gradient(90deg, #e7b00c 39.74%, #fedd00 60.06%);
  background-clip: text;
  color: transparent;
  -webkit-background-clip: text;
}
.form-btn:hover {
  background-image: linear-gradient(
    90deg,
    #0c4e5a 0%,
    #0c4c58 15.19%,
    #093745 42.89%,
    #06232b 76.17%
  );
}
@media (max-width: 845px) {
  .form-appointment-container {
    width: 94%;
    margin: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 3%;
  }
  .t-input-long {
    width: calc(100% - 32px);
    border-radius: 8px;
    padding: 12px 16px;
    border: solid 1px #777777;
    margin-bottom: 20px;
    color: #777777;
  }
  .text-question {
    text-align: justify;
    margin: 0px;
    padding: 0px;
  }
  .form-time {
    display: flex;
    flex-direction: column;
  }
  .t-input-short-cont {
    width: calc(100% - 18px);
    margin-top: 20px;
  }
  .form-btn {
    width: calc(100vw - 16px);
    padding: 12px 0px;
  }
}
</style>
